.footer {
  background-color: #2e2e2e;
  padding-top: 1rem;
  padding-bottom: 4rem;
}

.title {
  color: #fff;
}

.description {
  color: #fff;
  font-size: 10px;
}

.footerlink,
.footerlink:hover {
  color: #fff;
}
