
$darkPurple: rgba(255, 0, 130, 0.5);
$button-bg: #CED6DD;
$button-text-color: black;
$baby-blue: rgba(255, 0, 130, 0.5);

$backgroundGrey:#2e2e2e;

$correct: #06af47;
$incorrect: rgb(224, 2, 2);

$facebook:#3b5998;
$twitter:#1b95e0;
$facebookmessenger:#0084FF;


$whiteAccent:#dee2e6;
$orangeAccent:#f77f2f;


$Fooddrink : #d40303;
$Art: #5f45ee;
$Science: #2cdd46;
$Sport: #2cc8dd; 
$General: #e86eec; 
$Geography: #9b0086; 
$Entertainment: #dadd2c; 
$History: #f77f2f;
$Tech: #8d2cdd;



html {
  position: relative;
  min-height: 100%;
}

body {
  margin-bottom: 60px !important; /* Height of the footer */
  padding: 0;
  background-image: url(BackgroundNew.png); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  background-position:center top;
  filter: alpha(opacity=60);
  color:$backgroundGrey;

  h1, h2{
    color:$backgroundGrey;
  }
  a{
    color:$orangeAccent;
  }
}


.navbar{
  background-color:$backgroundGrey;
  color:white;
}


.navbar-brand:hover{
  color:white;
  text-decoration: underline;
}
.navbar-light .navbar-brand{
  color:white !important;
}
.catMenu a{
  color:$orangeAccent;
}


.catIcon{
  font-size:16px;
  line-height:1;
  padding:15px;
  padding-left:15px;
  padding-right:15px;
  margin-top:3px; 
  margin-bottom:3px !important; 
  //flex-basis: auto;
  display:inline-block;
  border-radius:10px;
  color:white;
  border:1px solid white;
}
.catIcon i{
  font-size: 20px;
}
.catContainer{
  float:right;
  margin-left:5px;
}
p{
  margin-bottom: 0px !important;
}

.loginButton{
  margin-right:15px;
}
.catName {
  font-size:22px;
}
body.Entertainment, p.Entertainment{
  background-color: rgba($Entertainment, 0.3); /* Old browsers */
}

div.Entertainment{

.catName,
.anotherDayIcon,
.catIcon{
  background-color:$Entertainment;
}

// .bubbly-button{
//   box-shadow: 0 2px 25px $Entertainment;
// }
  //filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$Entertainment', endColorstr='#f9f9f9',GradientType=0 ); /* IE6-9 */
}

body.Geography, p.Geography{
  background-color: rgba($Geography, 0.3); /* Old browsers */
}
div.Geography{
  .catName,
  .anotherDayIcon,
  .catIcon{
    background-color:$Geography;
  }
  // .bubbly-button{
  //   box-shadow: 0 2px 25px $Geography;
  // }
}

body.Technology, p.Technology{
  background-color: rgba($Tech, 0.3); /* Old browsers */
}
div.Technology{

  .catName,
  .anotherDayIcon,
  .catIcon{
    background-color:$Tech;
  }
  
  // .bubbly-button{
  //   box-shadow: 0 2px 25px $Tech;
  // }

}


body.General, body.GeneralKnowledge, p.General, p.GeneralKnowledge{
  background-color: rgba($General, 0.3); /* Old browsers */
}
div.GeneralKnowledge,
  div.General{

  .catName,
  .anotherDayIcon,
  .catIcon{
    background-color:$General;
  }
  // .bubbly-button{
  //   box-shadow: 0 2px 25px $General;
  // }
}


body.Sport, p.Sport{
  background-color: rgba($Sport, 0.3); /* Old browsers */
}
div.Sport{
  .catName,
  .anotherDayIcon,
  .catIcon{
    background-color:$Sport;
  }
  // .bubbly-button{
  //   box-shadow: 0 2px 25px $Sport;
  // }
}


body.History, p.History{
  background-color: rgba($History, 0.3); /* Old browsers */
}
div.History{

  .catName,
  .anotherDayIcon,
  .catIcon{
    background-color:$History;
  }
  // .bubbly-button{
  //   box-shadow: 0 2px 25px $History;
  // }
}



body.Science, p.Science{
  background-color: rgba($Science, 0.3); /* Old browsers */
}
div.Science{
 .catName,
    .anotherDayIcon,
  .catIcon{
    background-color:$Science;

  }
  // .bubbly-button{
  //   box-shadow: 0 2px 25px $Science;
  // }
}


body.Art, p.Art{
  background-color: rgba($Art, 0.3); /* Old browsers */
}
div.Art{

  .catName,
  .anotherDayIcon,
  .catIcon{
    background-color:$Art;
  }
  // .bubbly-button{
  //   box-shadow: 0 2px 25px $Art;
  // }
}


body.FoodDrink, body.Food, p.Food, p.FoodDrink{
  background-color: rgba($Fooddrink, 0.3);
}
div.FoodDrink,div.Food{
  .catName,
  .anotherDayIcon,
  .catIcon{
    background-color:$Fooddrink;
  }
  // .bubbly-button{
  //   box-shadow: 0 2px 25px $Fooddrink;
  // }
}



footer {
  position: absolute;
  bottom: 0;
  width: 100%;

  height: 60px; /* Set the fixed height of the footer here */
}
.footer_footer__2F53t
{
  padding-bottom:15px !important;
}

.button{
  padding:5px;
  border-radius:10px;
  background-color: #E7E7E7;
  color: #333333;
}
.italics{
  font-style: italic;
}

.anotherDayIcon{
  font-size:74px;
  text-align: center;
}
/* Override Bootstrap Styling */

.btn-primary {
  background-color: #025fce !important;
}

button:focus,
button:active {
  outline: none !important;
  box-shadow: none !important;
}

footer a, footer a:visited,footer a:active,footer a:focus
{
  color: #BBBBBB ;
font-size:10px;
}

#mainContent a, #mainContent a:visited, #mainContent a:active,#mainContent a:focus
{color: #333333;} 

#mainContent{
  overflow:hidden;
}

.quizNav,
.quizDescription{
  padding-bottom:20px;
}

.arrow
{margin:auto;
  display:block
}
.newDate a:hover{
  text-decoration: none;
}
.newDate a:hover > span{
  text-decoration: italic;
  font-weight:bold ;
}

.newDate{
  text-align: center;
  margin-bottom:20px;
}

.questionContainer{
  text-align:center;
  color: white;
  width: 100%;
  padding-bottom:10px;
  padding-top:10px;
  padding-left:20px;
  padding-right:20px;
  margin-left: auto;
  margin-right: auto;
margin-bottom:10px;
border-radius:10px;
}


.questionContainer.Entertainment{
  background: rgba($color: $Entertainment, $alpha: .8);
}
.questionContainer.General{
  background: rgba($color: $General, $alpha: 0.8);
}
.questionContainer.Science{
  background: rgba($color: $Science, $alpha: 0.8);
}
.questionContainer.Art{
  background: rgba($color: $Art, $alpha: 0.8);
}
.questionContainer.Technology{
  background: rgba($color: $Tech, $alpha: 0.8);
}
.questionContainer.Food{
  background: rgba($color: $Fooddrink, $alpha: 0.8);
}
.questionContainer.Geography{
  background: rgba($color: $Geography, $alpha: 0.8);
}
.questionContainer.History{
  background: rgba($color: $History, $alpha: 0.8);
}
.questionContainer.Sport{
  background: rgba($color: $Sport, $alpha: 0.8);
}

.selectableQuestion{
  background: rgba($color: #737373, $alpha: 0.7);
  color: white;
  width: 100%;
  border-radius:10px;
}
.quizContainer {
  background: rgba($color: #737373, $alpha: 0.7);
  padding:15px;
  border-radius:5px;
}
.anotherDay{
  margin-bottom:20px;
}
.quizContainer a:hover,
  .quizContainer a{
  color:white !important;
  text-decoration: none;
}

.react-multi-carousel-item{
  margin:7px;
}

.quizHeading{
  font-size:24px;
}
.quizNavHeading{
  font-size:18px;
}
.quizContainer:hover .quizHeading{
  text-decoration: underline;
}

.anchorToFooter{
  position:fixed;
  bottom:0px;
  width:60%;
  margin-left:20%;
  background-color:$orangeAccent;
  color:white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding:5px;
  z-index:50;
}
.quizDesc{
  font-size:14x;
}
.quizByline{
  font-size:11px;
}
.quizScore{
  float:right;

}

.catName{
  padding-left:10px;
  
}
  .pageContent{padding:50px;

  }

 footer .fab,
 .btn-fbshare .fab,
 .fbButton .fab{
    padding-right:10px;
  }

footer .fab{
  font-size: 28px;
}

/* Social Buttons */
.shareRow{
  margin-top:10px;
  
}


  .btn-fbshare
  {
  background:$facebook;
  border:0px;
  color:white;
  border-radius:5px;
  margin-right:5px;
}
.fb-login-button {
  margin-top:10px;
  background-color: #1877f2;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.twitter-share-button {
  background:$twitter;
  border-radius: 5px !important;
  color: #fff !important;
  border-width: 0px;
  border-style: solid;
  margin-left:5px;
  padding:0px 0px 0px 0px !important;
}

.btn-fbshare, .twitter-share-button{
  top:-5.5px;
  position: relative;
  padding-top:2px;
  padding-bottom:2px;
}

.btn-twitter:link, .btn-twitter:visited, .btn-twitter:active {
  color: #fff; 
}
.btn-twitter:active, .btn-twitter:hover,
.btn-fbshare:active, .btn-fbshare:hover {
  opacity: 0.7;
}


.shareRow, .shareRow i{
  color:white ;
  font-size:11px
}

  .slogan{
    font-size: 12px;
  margin-bottom:7px;
  color:#EEEEEE;
  font-style:oblique;
  display: block;
  left:0px;
  top:60px;
  text-align: center;
  width:100%
}
.logo{
  float:left;
  margin-right:15px;
  height:60px;
}
.logoName{
  float:left;
  margin-right:5px;
  height:100px;
}




.fbButton, .ggButton {
   width:100%;
  text-decoration: none;
  line-height: 1;
  position: relative;
  border-radius: 5px;
  border:0px;
  background-color: rgb(255, 255, 255); 
  display: inline-flex; 
  align-items: center; 
  color: rgba(0, 0, 0, 0.54); 
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px; 
  padding: 0px; 
  border-radius: 2px; 
  border: 1px solid transparent; 
  font-size: 14px; 
  font-weight: 500; 
  font-family: Roboto, sans-serif;
  text-align:center;
  margin-top:5px
}
.ggButton{
  margin-top:15px;
}
.fbButton i{
  font-size:18px;
  width:18px !important;
  display:inline-block;
  margin-right:25px;
  margin-left:5px;
}
.ggButton div, .ggButton span, .fbButton i, .fbButton span{
  padding: 10px 5px 10px 8px !important;
  display:inline-block;

}
.fbButton {
  background:$facebook;
  color: #fff;

}

.textField{
  width:100%;
  text-align:center;
  margin:auto;
  margin-top:4px;
}


.profile{
  width:32px;
  height:32px;
  border-radius:50%;
  margin-left:10px;
  margin-right:10px;
}

.loggedInUser{
  font-size: 12px;

}







//Bubble Button
.bubbly-button{
  font-family: 'Helvetica', 'Arial', sans-serif;
  display: inline-block;
  font-size: 1em;
  padding: 15px 15px;
  margin-top: 20px;
  margin-bottom: 20px;
  -webkit-appearance: none;
  appearance: none;
  background-color: $button-bg;
  color: $button-text-color;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  position: relative;
  transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;
  
  &:focus {
    outline: 0;
  }
  
  &:hover{
    background-color: darken($button-bg, 5%);
  }

  &:before, &:after{
    position: absolute;
    content: '';
    display: block;
    width: 140%;
    height: 100%;
    left: -20%;
    z-index: -1000;
    transition: all ease-in-out 0.5s;
    background-repeat: no-repeat;
  }
  
 
  &:active{
    transform: scale(0.9);
    background-color: darken($button-bg, 5%);
    box-shadow: 0 2px 25px;
  }
  
  &.animate{
    &:before{
      display: block;
      animation: topBubbles ease-in-out 0.75s forwards;
    }
    &:after{
      display: block;
      animation: bottomBubbles ease-in-out 0.75s forwards;
    }
  }
}

.bubbly-button-standard{

  &:before{
    display: none;
    top: -75%;
    background-image:  
      radial-gradient(circle, $darkPurple 20%, transparent 20%),
    radial-gradient(circle,  transparent 20%, $button-bg 20%, transparent 30%),
    radial-gradient(circle, $button-bg 20%, transparent 20%), 
    radial-gradient(circle, $darkPurple 20%, transparent 20%),
    radial-gradient(circle,  transparent 10%, $button-bg 15%, transparent 20%),
    radial-gradient(circle, $button-bg 20%, transparent 20%),
    radial-gradient(circle, $darkPurple 20%, transparent 20%),
    radial-gradient(circle, $button-bg 20%, transparent 20%),
    radial-gradient(circle, $darkPurple 20%, transparent 20%);
  background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%, 10% 10%, 18% 18%;
  //background-position: 0% 80%, -5% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 85% 30%;
  }
  
  &:after{
    display: none;
    bottom: -75%;
    background-image:  
    radial-gradient(circle, $darkPurple 20%, transparent 20%), 
    radial-gradient(circle, $button-bg 20%, transparent 20%),
    radial-gradient(circle,  transparent 10%, $button-bg 15%, transparent 20%),
    radial-gradient(circle, $darkPurple 20%, transparent 20%),
    radial-gradient(circle, $button-bg 20%, transparent 20%),
    radial-gradient(circle, $button-bg 20%, transparent 20%),
    radial-gradient(circle, $darkPurple 20%, transparent 20%);
  background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 10% 10%, 20% 20%;
  //background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
  }

}


.bubbly-button-right{

  &:before{
    display: none;
    top: -75%;
    background-image:  
      radial-gradient(circle, $correct 20%, transparent 20%),
    radial-gradient(circle,  transparent 20%, $button-bg 20%, transparent 30%),
    radial-gradient(circle, $button-bg 20%, transparent 20%), 
    radial-gradient(circle, $correct 20%, transparent 20%),
    radial-gradient(circle,  transparent 10%, $button-bg 15%, transparent 20%),
    radial-gradient(circle, $button-bg 20%, transparent 20%),
    radial-gradient(circle, $correct 20%, transparent 20%),
    radial-gradient(circle, $button-bg 20%, transparent 20%),
    radial-gradient(circle, $correct 20%, transparent 20%);
  background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%, 10% 10%, 18% 18%;
  //background-position: 0% 80%, -5% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 85% 30%;
  }
  
  &:after{
    display: none;
    bottom: -75%;
    background-image:  
    radial-gradient(circle, $correct 20%, transparent 20%), 
    radial-gradient(circle, $button-bg 20%, transparent 20%),
    radial-gradient(circle,  transparent 10%, $button-bg 15%, transparent 20%),
    radial-gradient(circle, $correct 20%, transparent 20%),
    radial-gradient(circle, $button-bg 20%, transparent 20%),
    radial-gradient(circle, $button-bg 20%, transparent 20%),
    radial-gradient(circle, $correct 20%, transparent 20%);
  background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 10% 10%, 20% 20%;
  //background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
  }

}


.bubbly-button-wrong{

  &:before{
    display: none;
    top: -75%;
    background-image:  
      radial-gradient(circle, $incorrect 20%, transparent 20%),
    radial-gradient(circle,  transparent 20%, $button-bg 20%, transparent 30%),
    radial-gradient(circle, $button-bg 20%, transparent 20%), 
    radial-gradient(circle, $incorrect 20%, transparent 20%),
    radial-gradient(circle,  transparent 10%, $button-bg 15%, transparent 20%),
    radial-gradient(circle, $button-bg 20%, transparent 20%),
    radial-gradient(circle, $incorrect 20%, transparent 20%),
    radial-gradient(circle, $button-bg 20%, transparent 20%),
    radial-gradient(circle, $incorrect 20%, transparent 20%);
  background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%, 10% 10%, 18% 18%;
  //background-position: 0% 80%, -5% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 85% 30%;
  }
  
  &:after{
    display: none;
    bottom: -75%;
    background-image:  
    radial-gradient(circle, $incorrect 20%, transparent 20%), 
    radial-gradient(circle, $button-bg 20%, transparent 20%),
    radial-gradient(circle,  transparent 10%, $button-bg 15%, transparent 20%),
    radial-gradient(circle, $incorrect 20%, transparent 20%),
    radial-gradient(circle, $button-bg 20%, transparent 20%),
    radial-gradient(circle, $button-bg 20%, transparent 20%),
    radial-gradient(circle, $incorrect 20%, transparent 20%);
  background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 10% 10%, 20% 20%;
  //background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
  }

}


@keyframes topBubbles {
  0%{
    background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
  }
    50% {
      background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 90% 30%;}
 100% {
    background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%, 50% 40%, 65% 10%, 90% 20%;
  background-size: 0% 0%, 0% 0%,  0% 0%,  0% 0%,  0% 0%,  0% 0%;
  }
}

@keyframes bottomBubbles {
  0%{
    background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%, 70% -10%, 70% 0%;
  }
  50% {
    background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%, 105% 0%;}
 100% {
    background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%, 110% 10%;
  background-size: 0% 0%, 0% 0%,  0% 0%,  0% 0%,  0% 0%,  0% 0%;
  }
}
//End Bubble Button





//Bubble Button
.button{
  font-family: 'Helvetica', 'Arial', sans-serif;
  display: inline-block;
  font-size: 14px;
  padding: 8px 8px;
  -webkit-appearance: none;
  appearance: none;
  background-color: $backgroundGrey;
  color: $orangeAccent;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  position: relative;
  transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;
  
  &:focus {
    outline: 0;
  }
  
  &:hover{
    background-color: darken($backgroundGrey, 5%);
  }

  &:before, &:after{
    position: absolute;
    content: '';
    display: block;
    width: 140%;
    height: 100%;
    left: -20%;
    z-index: -1000;
    transition: all ease-in-out 0.5s;
    background-repeat: no-repeat;
  }
  
 
  &:active{
    transform: scale(0.9);
    background-color: darken($button-bg, 5%);
    box-shadow: 0 2px 25px;
  }
  
}

.buttonForm{
  width:100%;
  margin:auto;
  margin-top:10px;
}


.answer{
  padding-top:10px;
  padding-bottom:5px;
 // border: 1px solid $darkPurple;
  border-radius: 10px;  
}

.moreInfo{
  font-size:24x;
  font-style: italic;
  color:#222222;
  padding-top:15px;
  padding-bottom:15px;
  background-color:rgba(204, 204, 204, 0.7);
  border-radius:5px;
  
}
.moreInfo br{
  margin-top:10px;
    display:block;
    line-height: 150%;
    content:" ";
  
}

.learnMore,
.learnMore a:visited,
.learnMore a:link,
.learnMore a:active,
.learnMore a:focus,
  .learnMore a{
  color:#444444 !important;
  font-size:12px;
  right:0px;
  position: relative;
  padding:5px;
  display:block;
  margin:auto;
}
.learnContainer{
  width:100%;
  display:block;
  position: relative;
  text-align:right;
  padding-top:10px;
}

.notRight,
.notRight:hover{
  background-color: $incorrect;
}

.correct,
.correct:hover{
  background-color: $correct;
}

.answerContainer button{
  width:100%;
  height:75%;
}


.bubbly-button-1{
  order:1;
}
.bubbly-button-2{
  order:2;
}
.bubbly-button-3{
  order:3;
}
.bubbly-button-4{
  order:4;
}


.btn-fblink:hover{
  color:#3b5998 ;

}
.btn-twlink:hover{
  color:#00acee;

}

.profMenu div{

  background-color: #fff;
  color:#222222;
    padding:4px;
  width:100%;
  float:right;
}

.profMenu{
  box-shadow:0 5px 8px rgba(0, 0, 0, 0.55);
  right:0px;
  position:fixed;
  width:80px;
  margin:10px;
  z-index:20;
}



.triv-nav,
.triv-nav i{
  background-color:$backgroundGrey !important;
  color:#dee2e6;

}

.dropdown-menu{
  background-color:$backgroundGrey !important;
  margin-top:0px !important;
  margin-bottom:0px !important;
  padding:0px !important;
  border-radius:5px !important;
  min-width:70% !important;
  margin-left:15% !important;
}
.dropdown-item{
  padding:3px !important;
}
.dropdown-item .nav-link{
  padding-left:10px !important;
  padding-top:5px !important;
  width:auto;
}
a.nav-link:active{
  background-color:$orangeAccent;
  color:white !important;
}
.profMenu .dropdown-item{
  background-color: white;
  padding-left:4px !important;
  text-align:left;
}


  .triv-nav nav{
    padding:0px !important;
  
  }

  .collapsedMenu{
    color:white;
    float:right;
  }

.triv-nav a{
  color: $whiteAccent;
}

.triv-nav a:hover,
.triv-nav a:active{
  color:$orangeAccent;
}


.contact i{
  font-size:60px;
}

.contact .fa-facebook{
color:$facebook

}

.contact .fa-facebook-messenger{
  color:$facebookmessenger;
  
  }

.contact .fa-twitter{
  color:$twitter;
}

.contact .fa-envelope{
  color:white;
}

.contactIcon{
  padding-bottom:20px;
}


.browserError{
  background-color:#444444;
  color:white;
  width:100%;
  padding:10px;
  text-align:center;
}

.error{
  color:Red;
  text-align:center;
}

.imgByline{
  font-size: 10px;
  color:#ddd;
}
.fb-quotable{
  font-size:30px;
}
@media (max-width:992px)
{
  .navbar-collapse{
    width: 200px; position: fixed !important;left:0px;background:$backgroundGrey;top:94px;
    z-index:1000;
  }
  .navbar{
    padding:0px !important;
  }
  .navbar .container{
    order:-1;
    margin-right:0px;
    margin-left:0px;
    padding-left:10px !important;
    width: 17%;
  }
  .loginContainer{
    width:17%;
  }
  .navLogo{
    width:49%;
    text-align:center !important;
    margin:auto !important;
  }
  .fb-quotable{
    font-size:16px;
  }
}
@media (min-width:400px)
{
  .navLogo{
    min-width:300px
  }
  .navbar{
    width:100%;
}

}


.navbar-brand{
  color:white;
  padding-left:10px;
  white-space: normal !important;
  padding-bottom:0px;
}
.navbar-light
{
  color:white
}
.quizImage{
  width:250px;
  max-width:100%;
  max-height:156px;
  margin:auto;
  object-fit: cover;
}
.quizImageNav{
  height:200px;
  margin:auto;
  object-fit: cover;
}
.quizImageHeader{
  width:250px;
  max-width:100%;
  max-height:156px;
  margin:auto;
  object-fit: cover;
  float:left;
  margin-bottom:20px;
  margin-right:20px;
}
.card{
  background:transparent !important;
}
.card-body
{
  height:200px;
  background: rgba($color:#cccccc, $alpha: 0.7);
}

.author{
  float:right;
  padding-top:10px;
  font-size:10px;
  text-align:center;

}
.authorContainer{
  bottom:0px;
  right:0px;
  position: absolute;
}
.row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;
  flex-wrap: wrap;
}
.row > [class*='col-'] {
  display: flex;
  flex-direction: column;
}



.footerIcons{
  display:inline !important;
  text-align: center;
}
.footerText,
.footerText a{
  font-size: 18px;
  color:$orangeAccent !important;
  text-align: center;
}
@media (max-width:760px){
  .footerText,
  .footerText a{
    font-size: 13px;
  }

}
.dropdown{
  width:auto;
}
.dropdown-item{
  text-align:left !important;
  padding-left:15px !important;
  padding-right:15px !important;
}


.contentContainer{
  padding-bottom:80px;
  padding-top:20px;

}
.hourGlassIcon{
  font-size:52px;
}
.quizText{
  margin-bottom:20px;
}


.freetextAnswer{
  padding-top:20px;
  padding-bottom:20px;
  font-size:28px;
}




.quizTimer{
  float:right;
  font-size:20px;
}